import React from 'react'
import './preloader.scss'

const ApiLoader = () => {
    return (
        <div class="lds-ring">
            <div>
            </div>
            <div>
            </div>
            <div>
            </div>
            <div>
            </div>
        </div>
    )
}
export default ApiLoader
